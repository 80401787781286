const Nav = require('bluegg-nav');
const FontFaceObserver = require('fontfaceobserver');
const createCookie = require('./modules/create-cookie');

// NAV

var optionsNav = new Nav({
	nav: '#nav', // should be unique
	trigger: '#primary-nav-trigger', // should be unique
	openClass: 'is-open',
	bodyClass: 'js-mobile-nav-open'
});

var filter = new Nav({
	nav: '#filter', // should be unique
	trigger: '#filter-toggle', // should be unique
	openClass: 'is-open',
	bodyClass: 'js-filter-open'
});

// FONT LOADING
if (!document.documentElement.classList.contains('fonts-loaded')) {
	var fontA = new FontFaceObserver('Freight Text', {
		weight: 'normal',
		style: 'normal'
	});
	var fontB = new FontFaceObserver('Freight Text', {
		weight: 'normal',
		style: 'italic'
	});
	var fontC = new FontFaceObserver('Freight Text', {
		weight: 'bold',
		style: 'normal'
	});
	var fontD = new FontFaceObserver('Freight Text', {
		weight: 'bold',
		style: 'italic'
	});
	var fontE = new FontFaceObserver('Sul Sans', {
		weight: 'bold',
		style: 'normal'
	});
	var fontF = new FontFaceObserver('Sul Sans', {
		weight: 'bold',
		style: 'italic'
	});

	Promise.all([
		fontA.load(),
		fontB.load(),
		fontC.load(),
		fontD.load(),
		fontE.load(),
		fontF.load()
	]).then(function() {
		document.documentElement.className += ' fonts-loaded';
		createCookie('fonts-loaded', true);
	});
}

// CSS LOADING
if (document.styleSheets['1']) {
	if (document.styleSheets['1'].ownerNode.nodeName === 'LINK') {
		createCookie('css-loaded', true);
	}
}
